import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyAkAU6b5c-7bDCcJeFRSEUsJem7qADtO90",
  authDomain: "hitech-logistics.firebaseapp.com",
  projectId: "hitech-logistics",
  storageBucket: "hitech-logistics.appspot.com",
  messagingSenderId: "532422145194",
  appId: "1:532422145194:web:c7f40d0261bc0ad112ccd3",
  measurementId: "G-42W3K2FYHE",
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const requestPermission = async () => {
  try {
    const notif = await Notification.requestPermission();
    console.log('notif',notif)
    if (notif === "granted") {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};
export const getDeviceToken = (setTokenFound) => {
  return getToken(messaging, {
    vapidKey:
      "BPRqcUOSFm7vm9L_fjav71_Qvrtaf8RRhxFZzKTpvXnNJ0lmDpf7OSkKJ8uUjWIYyjAkRNygOEWuUkVZWcw8MgI",
  })
    .then((currentToken) => {
      if (currentToken) {
        setTokenFound(currentToken);
        return currentToken;
      } else {
        setTokenFound(false);
        return null;
      }
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
};
onMessage(messaging, (payload) => {
  navigator.serviceWorker
    .getRegistration("/firebase-cloud-messaging-push-scope")
    .then((registration) => {
      registration.showNotification(payload.notification.title, {
        icon: payload.notification.image,
      });
    });
});
