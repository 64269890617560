import { Button } from "@mui/material";
import PropTypes from "prop-types";
import { all_fonts } from "utils/constants";

export default function LogisticsButton(props) {
  const { ButtonLabel, style, onClick, disabled } = props;
  return (
    <div>
      <Button
        style={style}
        onClick={onClick}
        disabled={disabled}
        variant="contained"
      >
        {ButtonLabel}
      </Button>
    </div>
  );
}
LogisticsButton.defaultProps = {
  ButtonLabel: "Button Label",
  style: {
    maxHeight: "45px",
    minWidth: "130px",
    minHeight: "45px",
    textTransform: "capitalize",
    boxShadow: "none",
    marginLeft: "8px",
    marginBottom: "5px",
    fontFamily: `${all_fonts.Roboto.font_c}`,
    color: "#f8f8f8",
    border: "1px solid #3344b5",
    fontSize: 16,
    background: "#3344b5",
    fontWeight: "none",
    lineHeight: 'normal',
    letterSpacing: "0.04em",
  },
};

LogisticsButton.propTypes = {
  ButtonLabel: PropTypes.string,
  disabled: PropTypes.bool,
};
