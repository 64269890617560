import * as React from "react";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Checkbox, Grid, InputAdornment, NativeSelect,TextField } from "@mui/material";
import { Close, Search, CheckCircleOutline } from "@mui/icons-material";
import Pagination from "@mui/material/Pagination";
import { all_fonts } from "utils/constants";
import LogisticsButton from "ui-component/LogisticsButton";

export default function DataTable(props) {
  const [rows, setRows] = React.useState(props.rows);
  // eslint-disable-next-line
  const [columns, setColumns] = React.useState(props.columns);
  const [value, setValue] = React.useState("");
  const [_sequence, set_sequence] = React.useState(null);
  const [_count, set_count] = React.useState(null);
  const [_index, set_index] = React.useState(null);

  const [selectedRowIndex, setSelectedRowIndex] = React.useState(null);
  const [selectedRows, setSelectedRows] = React.useState(
    props.selectedData || []
  );
  const {
    caption,
    CaptionBody,
    pageNumber,
    count,
    ariaLabel,
    enablePagination,
    onRowClick,
    radioButton,
    selectedRowColor,
    searchSize,
    searchEnable,
    checkBox,
    rowIconColor,
    onCheckChange,
    table_width,
    header_color,
    show_calculation,
    Calculation,
    min_width,
    show_button,
    button_click,
    button_label,
    btn_disable,
    classes,
    __style
  } = props;

  React.useEffect(() => {
    if (props.rows) {
      setRows(props.rows);
    }
  }, [props.rows]);

  React.useEffect(() => {
    if (props.selectedRow) {
      setSelectedRowIndex((props.selectedRow || {}).index);
    }
  }, [props.selectedRow]);

  const _sortRow = (index) => {
    try {
      if (_sequence) {
        let sortedRows = props.rows.sort((a, b) =>
          a[index] > b[index] ? 1 : -1
        );
        setRows(sortedRows);
      } else if (_sequence === null) {
        let sortedRows = props.rows.sort((a, b) => (a[0] > b[0] ? 1 : -1));
        setRows(sortedRows);
      } else if (_sequence === false) {
        let sortedRows = props.rows.sort((a, b) =>
          a[index] > b[index] ? -1 : 1
        );
        setRows(sortedRows);
      }
    } catch (error) {}
  };

  React.useMemo(() => 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  _sortRow(_index), [_count]
  );

  const onClickRow = (event, row, index) => {
    try {
      setSelectedRowIndex(index);
      onRowClick(event, row, index);
    } catch (error) {
      console.log(error);
    }
  };

  const onRowCheck = (event, row, index) => {
    try {
      let arr = selectedRows;
      const isPresent = selectedRows.findIndex((item) => item.id === row[0]);

      if (isPresent < 0) {
        arr.push({ id: row[0], row, index });
        setSelectedRows(arr);
      } else {
        arr.splice(isPresent, 1);
        setSelectedRows(arr);
      }
      onCheckChange(arr);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Paper>
      {searchEnable ? (
        <Grid
          container
          spacing={2}
          style={{
            margin: 0,
            width: "100%",
            height: "80px",
            display: "flex",
            flexDirection: "row",
           }}
        >
          <Grid item xs={12} md={4}>
            <TextField
             className={`${classes?.text_feild_hover} ${classes?.text_feild}`}
            placeholder="Search"
              size={searchSize}
              fullWidth={true}
               title={"Search"}
              value={value}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {value ? (
                      <Close
                        onClick={() => {
                          setRows(props.rows);
                          setValue("");
                        }}
                        style={{ color: "red", cursor: "pointer" }}
                      />
                    ) : (
                      <Search style={{ color: "gray" }} />
                    )}
                  </InputAdornment>
                ),
                style: {
                  fontSize: "16px",
                  color: "#3344b5",
fontFamily:all_fonts.Roboto.font_c

                },
              }}
              onChange={(e) => {
                setValue(e.target.value);
                const filter = props.rows.filter((item) =>
                  item.some((row) =>
                    (row || {}).name
                      ? `${(row || {}).name}`
                          .toLowerCase()
                          .indexOf(e.target.value.toLowerCase()) !== -1
                      : `${row}`
                          .toLowerCase()
                          .indexOf(e.target.value.toLowerCase()) !== -1
                  )
                );
                setRows(filter);
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={searchEnable ? 8 : 12}
            style={{
              display: "flex",
              alignItems: "center",
              paddingLeft: 20,
              paddingTop: "2px",
              justifyContent: searchEnable ? "" : "end",
            }}
          >
            <span
              style={{
                margin: 0,
                fontSize: 18,
                color: "gray",
                flex: 1, 
              }}
            >
              {rows.length === 0
                ? "No Record Found"
                : `Total ${rows.length} ${
                    rows.length > 1 ? "Records" : "Record"
                  }`}
            </span>
            {show_button && (
              <LogisticsButton
                style={{
                  float: "right",
                  backgroundColor: "white",
                  color: "#3344b5",
                  border: "1px solid #3344b5",
                  marginRight:'15px',
                  fontSize:'16px'
                  
                }}
                disabled={btn_disable}
                ButtonLabel={button_label}
                onClick={button_click}
              />
            )}
          </Grid>
        </Grid>
      ) : null}

      <TableContainer style={__style}>
        <Table
          style={
            (rows || []).length > 0
              ? {
                  width: table_width,
                  margin: 0,
                  marginBottom: 15,
                }
              : { width: table_width }
          }
          sx={{ minWidth: min_width }}
          aria-label={ariaLabel}
        >
          {caption ? <CaptionBody /> : null}
          <TableHead
            className={header_color ? classes.customer_table_parent : ""}
          >
            <TableRow
              className={header_color ? classes.customer_table_parent : ""}
              style={
                header_color
                  ? null
                  : {
                      backgroundColor: "#eeeeee",
                    }
              }
            >
              {radioButton || checkBox ? <TableCell align={"left"} /> : null}
              {columns.length > 0 &&
                columns.map((col, index) => (
                  <TableCell
                 
                    className={`${
                      header_color ? classes.font_color_parent : ""
                    } padding-10 ${all_fonts.Roboto.font_g}`}
                    key={index}
                    align={col.alignment}
                    onClick={() => {
                      set_count(_count + 1);
                      set_index(index);
                      switch (_sequence) {
                        case true:
                          set_sequence(false);
                          break;
                        case false:
                          set_sequence(null);
                          break;
                        case null:
                          set_sequence(true);
                          break;
                      }
                    }}
                    style={{
                      cursor: "pointer",
                      color: "#0f1436",
                      fontSize: "19px",
                      fontWeight: "normal",
                      paddingLeft:'11px'
                    }}
                  >
                    <span style={{ marginRight: 10 }}>{col.name}</span>
                    {_sequence === null ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 32 32"
                        style={{
                          color: header_color ? "#a4a4a4" : "#a4a4a4",
                          height: 18,
                          stroke: header_color ? "#a4a4a4" : "#a4a4a4",
                          strokeWidth: 2,
                        }}
                      >
                        <title>Sort</title>
                        <path d="M10 26.586V2H8V26.586l-3.95-3.95L2.636 24.05l5.657 5.657a1 1 0 0 0 1.414 0l5.657-5.657L13.95 22.636zM29.364 7.95L23.707 2.293a1 1 0 0 0-1.414 0L16.636 7.95 18.05 9.364 22 5.414V30h2V5.414l3.95 3.95z" />
                      </svg>
                    ) : index === _index && _sequence === true ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        style={{
                          height: 17,
                          stroke: "white",
                          strokeWidth: 1,
                        }}
                      >
                        <title>Sort</title>
                        <path d="M17 10c-.3 0-.5-.1-.7-.3l-5-5c-.4-.4-.4-1 0-1.4s1-.4 1.4 0l5 5c.4.4.4 1 0 1.4-.2.2-.4.3-.7.3z" />
                        <path d="M7 10c-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l5-5c.4-.4 1-.4 1.4 0s.4 1 0 1.4l-5 5c-.2.2-.4.3-.7.3z" />
                        <path d="M12 21c-.6 0-1-.4-1-1V4c0-.6.4-1 1-1s1 .4 1 1v16c0 .6-.4 1-1 1z" />
                      </svg>
                    ) : index === _index && _sequence === false ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        style={{
                          height: 17,
                          stroke: "white",
                          strokeWidth: 1,
                        }}
                      >
                        <title>Sort</title>
                        <path d="M11.293,22.707a1,1,0,0,0,1.414,0l6-6a1,1,0,0,0-1.414-1.414L13,19.586V2a1,1,0,0,0-2,0V19.586L6.707,15.293a1,1,0,0,0-1.414,1.414Z" />
                      </svg>
                    ) : null}

                  </TableCell>
                ))}
                       
            </TableRow>
          </TableHead>
          {(rows || []).length > 0 ? (
            <TableBody >
              {rows.map((row, index) => (
                <TableRow
                
                  onClick={(event) => onClickRow(event, row, index)}
                  key={index}
                  className={selectedRowColor}
                  style={
                    radioButton
                      ? selectedRowIndex === index
                        ? null
                        : {
                            backgroundColor:
                              index % 2 === 0 ? "#fff" : "#f4f4f4",
                            opacity: 1,
                          }
                      : {
                          backgroundColor: index % 2 === 0 ? "#fff" : "#e6e6e6",
                        }
                  }
                >
                  {checkBox === true ? (
                    <TableCell
                      className={`padding-10 ${all_fonts?.Roboto?.font_c}`}
                      align={"left"}
                      style={{fontSize:'16px'}}
                    >
                      <Checkbox
                        checked={
                          selectedRows.findIndex((i) => i.index === index) < 0
                            ? false
                            : true
                        }
                        onChange={(event) => onRowCheck(event, row, index)}
                      />
                    </TableCell>
                  ) : null}
                  {radioButton === true ? (
                    <TableCell
                      align={"left"}
                      style={{
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 8,
                      }}
                      className={`padding-10 ${all_fonts?.Roboto?.font_c}`}
                    >
                      {selectedRowIndex === index ? (
                        <CheckCircleOutline
                          style={
                            rowIconColor
                              ? {}
                              : {
                                  background: "#08193e",
                                  borderRadius: "100%",
                                  color: "white",
                                  fontSize: 20,
                                }
                          }
                          className={rowIconColor}
                        />
                      ) : null}
                    </TableCell>
                  ) : null}
                  {row.map(
                    (item, cellIndex) =>
                      !item?.hidden && (
                        <TableCell
                        
                            title={(item || {}).hover ? (item || {}).hover : null}
                          className={`table-cell-body-style padding-10 ${all_fonts?.Roboto?.font_c}`}
                          key={cellIndex}
                          style={(item || {}).style ? (item || {}).style : null}
                          align={
                             (item || {}).alignment
                              ? (item || {}).alignment
                              : "left"
                          }
                        >
                          {(item || {}).name ? (item || {}).name : item}
                        </TableCell>
                      )
                  )}
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow colSpan={columns.length} rowSpan={columns.length}>
                <TableCell
                  colSpan={columns.length}
                  rowSpan={columns.length}
                  align="center"
                  className={"table-cell-body-style padding-10"}
                >
                  NO RECORDS FOUND
                </TableCell>
              </TableRow>
            </TableBody>
          )}
          {show_calculation ? <Calculation /> : null}
        </Table>
      </TableContainer>
      {enablePagination ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Pagination
            count={count}
            page={pageNumber}
            color="primary"
            size={"large"}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            variant="outlined"
            showFirstButton
            showLastButton
          />
          &emsp;
          <NativeSelect
            defaultValue={50}
            inputProps={{
              name: "rows_page",
              id: "uncontrolled-native",
            }}
          >
            <option value={10}>10 Rows Per Page</option>
            <option value={50}>50 Rows Per Page</option>
            <option value={100}>100 Rows Per Page</option>
          </NativeSelect>
        </div>
      ) : null}
 
      
    </Paper>
  );
}

DataTable.defaultProps = {
  ariaLabel: "Table",
  table_width: "max-content",
  selectedRowColor: "",
  searchSize: "medium",
  pageNumber: 1,
  count: 1,
  caption: false,
  enablePagination: false,
  radioButton: false,
  searchEnable: true,
  checkBox: false,
  columns: [],
  rows: [],
  selectedRow: { index: null },
  CaptionBody: () => <caption>My Table captions</caption>,
  onCheckChange: () => {},
  onRowClick: () => {},
  Calculation: () => <></>,
  show_calculation: false,
  header_color: false,
  min_width: 1350,
  show_button: false,
  btn_disable: false,
  button_click: () => {},
  button_label: "",
  __style:{
    height:'80vh',
    overFlow:'scroll',
    borderRadius:'0px'
  }
};

DataTable.propTypes = {
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  caption: PropTypes.bool,
  enablePagination: PropTypes.bool,
  radioButton: PropTypes.bool,
  searchEnable: PropTypes.bool,
  checkBox: PropTypes.bool,
  CaptionBody: PropTypes.func,
  onCheckChange: PropTypes.func,
  onRowClick: PropTypes.func,
  pageNumber: PropTypes.number,
  count: PropTypes.number,
  ariaLabel: PropTypes.string,
  searchSize: PropTypes.string,
  table_width: PropTypes.string,
  show_calculation: PropTypes.bool,
  Calculation: PropTypes.func,
  header_color: PropTypes.bool,
  show_button: PropTypes.bool,
  button_click: PropTypes.func,
  button_label: PropTypes.string,
  btn_disable: PropTypes.bool,
  
};
