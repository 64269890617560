import { Grid } from "@mui/material";
import React from "react";
import MainCard from "ui-component/cards/MainCard";
import DataTable from "ui-component/Tables/DataTable";
import { useNavigate } from "react-router-dom";
import apiServices from "services/RequestHandler";
import Loader from "Loader";
import moment from "moment";

export default function OnlineRequestList(props) {
  const navigate = useNavigate();
  // eslint-disable-next-line
  const [loader, setLoader] = React.useState(true);
  const [screen_render, setScreen_render] = React.useState(true);
  const [state, setState] = React.useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      users: [],
      DataisLoaded: false,
      table_data: [],
      checked: true,
      // userStatus: ''
    }
  );

  React.useEffect(() => {
    getSubscriber();
  }, []);

  const handleRedirect = (e, data) => {
    try {
      navigate(`/app/add-subscriber/${data[0]}/online-req`);
    } catch (error) {}
  };

  const getSubscriber = async () => {
    let type = {
      1: "Free Trial",
      2: "Basic",
      3: "Standard",
      4: "Premium",
    };
    try {
      const response = await apiServices.getSubs("/");
      setState({ users: response.data });
      let table_data = [];
      let sorted_users = response?.data?.sort((a, b) =>
        new Date(a.createdAt).getTime() > new Date(b.createdAt).getTime()
          ? -1
          : 1
      );
      sorted_users?.map((item) => {
        if (!item?.user) {
          let arr = [
            item?.id || "-",
            item?.createdAt
              ? moment(item?.createdAt).format("DD-MMM-YYYY HH:mm")
              : "---",
            item?.companyName || "-",
            item?.firstName ? `${item?.firstName} ${item?.lastName} ` : "-",
            item?.email ? item?.email : "-",
            type[item?.subscription_id] || "-",
            item?.is_verified_email === true ? "Verified" : "Not Verified",

            item?.is_actived ? "Active" : "Inactive",
          ];
          table_data.push(arr);
        }
      });
      setState({
        table_data: table_data,
      });
      setLoader(false);
      setScreen_render(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  return (
    <div
      style={{
        filter: screen_render && "blur(5px)",
      }}
    >
      <Loader open={screen_render} />
      <MainCard title="Online Requests">
        <Grid container>
          <Grid xs={12} sm={12} item>
            <DataTable
              columns={[
                { name: "ID" },
                { name: "Request Date" },
                { name: "Subscriber" },
                { name: "Contact" },
                { name: "Email" },
                { name: "Subscription Type" },
                { name: "Email Verified" },
                { name: "Status" },
              ]}
              rows={state.table_data}
              table_width={"max-content"}
              classes={props.classes}
              searchSize="small"
              onRowClick={(e, data, index) => handleRedirect(e, data, index)}
              style={{ padding: 10, fontFamily: "RobotoCondensed-Medium" }}
            />
          </Grid>
        </Grid>
      </MainCard>
    </div>
  );
}
