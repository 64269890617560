import { useState, useRef, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  Chip,
  ClickAwayListener,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Snackbar,
  SnackbarContent,
  Stack,
  Typography,
} from "@mui/material";

// third-party
import PerfectScrollbar from "react-perfect-scrollbar";

// project imports
import MainCard from "ui-component/cards/MainCard";
import Transitions from "ui-component/extended/Transitions";
import User1 from "assets/images/users/user-round.svg";
import Login3 from "views/pages/authentication/authentication3/Login3";

// assets
import { IconLogout, IconSettings } from "@tabler/icons";
import localStoreUtil from "utils/localstore.util";
import { authClass } from "utils/auth.utils";
import { all_fonts } from "utils/constants";

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
  const theme = useTheme();
  const customization = useSelector((state) => state.customization);
  const navigate = useNavigate();
  // eslint-disable-next-line
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [open, setOpen] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [user, setUser] = useState();
  const [greeting, setGreeting] = useState("");
  useEffect(() => {
    GetData();
    getGreeting();
  }, []);

  const GetData = async () => {
    setUser(await localStoreUtil.get_data("user"));
  };
  const getGreeting = () => {
    const time = new Date();
    const hours = time?.getHours();
    if (hours < 12) {
      setGreeting("Morning");
    } else if (hours >= 12 && hours < 17) {
      setGreeting("Afternoon");
    } else {
      setGreeting("Evening");
    }
  };

  const anchorRef = useRef(null);

  const handleLogout = async () => {
    setSnackOpen(true);
    setTimeout(() => {
      localStoreUtil.remove_all();
      authClass.setUser = null;
      navigate(<Login3 />);
    }, 1000);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
    
      <Chip
        style={{ backgroundColor: "#3344b5", color: "white" }}
        sx={{
          height: "40px",
          alignItems: "center",
          borderRadius: "27px",
          transition: "all .2s ease-in-out",

          backgroundColor: theme.palette.primary.light,
          '&[aria-controls="menu-list-grow"], &:hover': {
            borderColor: theme.palette.primary.main,
            background: `${theme.palette.primary.main}!important`,
            color: theme.palette.primary.light,
            "& svg": {
              stroke: theme.palette.primary.light,
            },
          },
          "& .MuiChip-label": {
            lineHeight: 0,
          },
        }}
        icon={
          <Avatar
            src={User1}
            sx={{
              ...theme.typography.mediumAvatar,
              margin: "8px 0 8px 8px !important",
              cursor: "pointer",
            }}
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            color="inherit"
          />
        }
        label={<IconSettings stroke={1.5} size="1.5rem" color={"white"} />}
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="info"
      />
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 14],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  border={false}
                  elevation={16}
                  content={false}
                  boxShadow
                  shadow={theme.shadows[16]}
                >
                  <Box sx={{ p: 2 }}>
                    <Stack>
                      <Stack direction="row" spacing={0.5} alignItems="center">
                        <Typography
                          sx={{
                            fontFamily: all_fonts.Roboto.font_c,
                            fontSize: 18,
                          }}
                        >
                          Good {greeting},
                        </Typography>

                        <Typography
                          component="span"
                          sx={{
                            fontFamily: all_fonts.Roboto.font_d,
                            fontSize: 18,
                          }}
                        >
                          {user?.firstName} {user?.lastName}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Box>
                  <PerfectScrollbar
                    style={{
                      height: "100%",
                      maxHeight: "calc(80vh - 220px)",
                      overflowX: "hidden",
                    }}
                  >
                    <Box sx={{ p: 1 }}>
                      <List
                        component="nav"
                        sx={{
                          width: "100%",
                          backgroundColor: theme.palette.background.paper,
                          borderRadius: "10px",
                          [theme.breakpoints.down("md")]: {
                            minWidth: "100%",
                          },
                          "& .MuiListItemButton-root": {
                            mt: 0.5,
                          },
                        }}
                      >
                        <ListItemButton
                          sx={{
                            borderRadius: `${customization.borderRadius}px`,
                          }}
                          selected={selectedIndex === 4}
                          onClick={handleLogout}
                        >
                          <ListItemIcon>
                            <IconLogout stroke={1.5} size="1.3rem" />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography variant="body2">Logout</Typography>
                            }
                          />
                        </ListItemButton>
                      </List>
                    </Box>
                  </PerfectScrollbar>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}


      </Popper>
    
    <Snackbar
    style={{ maxWidth: "450px",zIndex:11111111 }}
    autoHideDuration={3000}
    open={snackOpen}
  >
    <SnackbarContent
      style={{
        backgroundColor: "#5CB85C",
        maxWidth: 450,
        alignItems: "center",
        fontSize: 20,
        fontFamily: "Roboto-Regular",
       }}
      message={"You've signed-out successfully."}
    />
  </Snackbar>
    </>
  );
 
};

export default ProfileSection;
