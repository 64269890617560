// ==============================|| LOGO SVG ||============================== //

const Logo = (props) => {
  const { style } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 297.68 176.32"
      style={style}
    >
      <title></title>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            className="cls-1"
            d="M14.68,86,70.81,54l-.67-1.59c-6.57,1.72-13.07,3.9-19.75,5-4.75.79-10,1.18-14.58,0C23.74,54.13,11.92,49.9,0,46.05L.16,44l77.9-20.27-.19-1.36c-9.33,0-18.68.29-28-.13-4.25-.19-9-.83-12.53-2.92C28.16,13.86,19.52,7.49,9.22.51,12.3.23,13.6,0,14.91,0Q87,.65,159.05,1.4c5.65.07,11.49.33,16.89,1.79,14.45,3.88,23.32,20.49,20.76,36.7C194.27,55.27,185.25,65,171.78,71.71c-15.9,8-33,11.14-50.29,13.64-30.78,4.44-61.69,4.93-92.67,2.51C24.7,87.54,20.61,86.79,14.68,86Z"
          />
          <path
            className="cls-1"
            d="M62.56,176.32c2.1-3.22,3.4-5.59,5-7.68,5.33-6.77,11.39-13.06,16.06-20.25,6.91-10.63,18.59-12.37,28.78-16.71,29-12.34,55.4-28.6,77-51.63C201.15,67.56,211,53.34,210.12,34.8c-.2-4.58-.54-9.41-2.21-13.58s0-6.37,2.77-8.47C230.26-2,252.47-3.35,273.25,8.93c8.86,5.25,16.33,12.85,24.43,19.38l-1,1.81c-3.62.7-7.24,1.38-10.84,2.12-16.1,3.3-27.76,12.62-35.53,26.84-4.22,7.73-7.9,15.77-11.78,23.69-17.7,36.17-47.72,55.76-86,64.79Q121.62,154.87,91.12,164C81.73,166.86,73,171.76,62.56,176.32Z"
          />
        </g>
      </g>
    </svg>
  );
};
Logo.defaultProps = {
  style: {
    fill: "white",
    width: "175px",
    height: "65px",
    marginTop: "15px",
  },
};
export default Logo;
