import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// material-ui
import { Card, CardContent, CardHeader, Divider, Typography } from '@mui/material';
import { all_fonts } from 'utils/constants';
// constant
const headerSX = {
    '& .MuiCardHeader-action': { mr: 0 }
};
// ==============================|| CUSTOM MAIN CARD ||============================== //
const MainCard = forwardRef(
    (
        {
            children,
            content = true,
            contentClass = '',
            contentSX = {},
            darkTitle,
            secondary,
            sx = {},
            title,
            ...others
        },
        ref
    ) => {

        return (
            <Card
            style={{borderRadius:'0px'}}
                ref={ref}
                {...others}
                elevation={3}
                sx={{
                   
                    ':hover': {
                     },
                    ...sx
                }}
            >
                {/* card header and action */}
                {!darkTitle && title && <CardHeader  style={{fontSize:"22px",fontFamily:all_fonts.Roboto.font_c,backgroundColor:'#E6E6FA',padding:'10px'}} sx={headerSX} title={  <span style={{color:'#0f1436',fontSize:'22px',fontFamily:all_fonts.Roboto.font_c}}> {title} </span>  } action={secondary}
                />}

                {darkTitle && title && (
                    <CardHeader style={{fontSize:'22px',fontFamily:`${all_fonts.Roboto.font_c}`}}  sx={headerSX}  title={<Typography  style={{fontSize:'22px',fontFamily:`${all_fonts.Roboto.font_c}`}} >  {title}</Typography>} action={secondary} />
                )}

                {/* content & header divider */}
                {title && <Divider  />}

                {/* card content */}
                {content && (
                    <CardContent   sx={contentSX} className={contentClass}>
                        
                        {children}
                    </CardContent>
                )}
                {!content && children}
            </Card>
        );
    }
);

MainCard.propTypes = {
    border: PropTypes.bool,
    boxShadow: PropTypes.bool,
    children: PropTypes.node,
    content: PropTypes.bool,
    contentClass: PropTypes.string,
    contentSX: PropTypes.object,
    darkTitle: PropTypes.bool,
    secondary: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
    shadow: PropTypes.string,
    sx: PropTypes.object,
    title: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object])
};

export default MainCard;
