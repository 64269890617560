export const features = {
  login: "login",
  addSub: "add-subscriber",
  getParent: "get-parentAPI",
  update: "update-subscriber",
  payment: "add-payment",
  updateToken: "update-token",
};
export const all_fonts = {
  Roboto: {
    font_a: "Roboto-Black",
    font_b: "Roboto-Bold",
    font_c: "Roboto-Medium",
    font_d: "Roboto-Regular",
    font_e: "Roboto-Italic",
    font_f: "RobotoCondensed-Bold",
    font_g: "RobotoCondensed-Medium",
    font_h: "RobotoCondensed-Regular",
  },
};
export const defaultLabelColors = {
  color_a: "#0f1436",
  color_b: "#3344b5",
  color_c: "#c2c7e9",
  color_d: "#e6e6fa",
  color_e: "#181818",
  color_h: "#f8f8f8",
  color_f: "#666666",
};

export const numberFormatter = (number) =>
  new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
  }).format(number);

export const errorMessage = {
  mandatoryField:
    "cannot be left blank or incomplete.",
};
