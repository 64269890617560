import * as React from "react";
import "./App.css";
import { useSelector } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";

 import Routes from "routes";
 import themes from "themes";
 import NavigationScroll from "layout/NavigationScroll";
import { useState } from "react";
import localStoreUtil from "utils/localstore.util";
import { authClass } from "utils/auth.utils";
import { getDeviceToken, requestPermission } from "firebase";
import apiServices from "services/RequestHandler";

// ==============================|| APP ||============================== //
const App = () => {
  const customization = useSelector((state) => state.customization);
  // eslint-disable-next-line
  const [isTokenFound, setTokenFound] = useState(null);

  React.useEffect(() => {
    getUser();
  }, []);
  React.useEffect(() => {
    checkForPermission();
  }, []);
  const checkForPermission = async () => {
    try {
      if (await requestPermission()) {
        const _token = await getDeviceToken(setTokenFound);
        // eslint-disable-next-line
        const newToken = await apiServices.updateToken("update-device-token/", {
          device_token: _token,
        });
      } else {
        alert(
          "Notification Permission Denied, please allow notification permision from browser settings"
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getUser = async () => {
    try {
      const _user = await localStoreUtil.get_data("user");
      if (_user) {
        authClass.setUser = _user;
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <CssBaseline />
        <NavigationScroll>
          <Routes />
        </NavigationScroll>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
export default App;
