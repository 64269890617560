// import {_secretData} from "./helper";
// var CryptoJS = require("crypto-js");

const localStoreUtil = {
  store_data: async (key, data) => {
    try {
      /**
       * 1. encrypte token
       * 2. save  encrypted token
       */
      // var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), _secretData).toString(); // this will encrypt the token
      // await localStorage.setItem(key, ciphertext); // setting encrypted token to local storage
      data = JSON.stringify(data);
      await localStorage.setItem(key, data); // setting encrypted token to local storage

      return true;
    } catch (error) {
      await localStorage.clear();
      return true;
    }
  },
  get_data: async (key) => {
    try {
      const item = await localStorage.getItem(key); // get item from localstorage behalf of key
      return JSON.parse(item); // get item from localstorage behalf of key
      // if (!item || item === undefined) return; // if the key is exists this step will skipped
      // const bytes = CryptoJS.AES.decrypt(item, _secretData); // it will decrypt the encrypted value get by behalf of key
      // const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8)); // the encrypted value is in string so we have to convert it in JSOn obj
      // if (!item || item === undefined) return; // if the key is exists this step will skipped
      // // console.log("dcry", decryptedData)
      // return decryptedData; // returned the object
    } catch (error) {
      await localStorage.clear();
      window.location.reload();
      return false;
    }
  },
  remove_data: async (key) => {
    await localStorage.removeItem(key);
    return true;
  },
  remove_all: async () => {
    await localStorage.clear();
    return true;
  },
};

export default localStoreUtil;
