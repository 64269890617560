import * as React from "react";
import { useRoutes } from "react-router-dom";

// routes
import AuthenticationRoutes from "./AuthenticationRoutes";
import config from "config";
import { lazy } from "react";
import Loadable from "ui-component/Loadable";
import { authClass } from "utils/auth.utils";
import MainLayout from "layout/MainLayout";
import apiServices from "services/RequestHandler";
import { withStyles } from "@mui/styles";
import style from "./style-jss";
import OnlineRequestList from "views/utilities/Online-Requests/OnlineRequestList";
import localStoreUtil from "utils/localstore.util";
const AuthLogin3 = Loadable(
  lazy(() => import("views/pages/authentication/authentication3/Login3"))
);
// dashboard routing
const DashboardDefault = Loadable(
  lazy(() => import("views/dashboard/Default"))
);
 const AllSubscribers = Loadable(
  lazy(() => import("views/Subscribers/AllSubscriber/AllSubscriber"))
);

 const AddNewSubscribers = Loadable(
  lazy(() => import("views/Subscribers/CreateSubscriber/SubscriberContainer"))
);
 

const UtilsInvoiceBill = Loadable(
  lazy(() => import("views/utilities/Invoice-Bill/InvoiceBillComponent"))
);
const InvoicesAndPayments = Loadable(
  lazy(() =>
    import(
      "views/utilities/Invoice-Bill/InvoiceAndPayments/InvoiceAndPaymentContainer"
    )
  )
);
const UtilsNotifications = Loadable(
  lazy(() => import("views/utilities/Notifications"))
);
const UtilsPayments = Loadable(
  lazy(() => import("views/utilities/All-payments/PaymentsContainer"))
);
  
// sample page routing
const SamplePage = Loadable(lazy(() => import("views/sample-page")));
// ==============================|| ROUTING RENDER ||============================== //

export function ThemeRoutes(props) {
  const { classes } = props;
  const [state, setState] = React.useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      countries: null,
      cities: null,
      mop: null,
      should_render: true,
      re_rendered: true,
    }
  );
  React.useEffect(() => {
    getDropDownData();
  }, []);
  const reRenDerApp = (re_render) => setState({ re_rendered: !re_render });
  React.useEffect(() => {
    getDropDownData();
  }, [state.re_rendered]);

  const getDropDownData = async () => {
    try {
      const [_countries, _cities, mop] = await Promise.all([
        await apiServices.getFromAPI("countries/web"),
        await apiServices.getFromAPI("cities/web"),
        await apiServices.getFromAPI("payment-modes"),
      ]);
      const user = await localStoreUtil.get_data("user");
      authClass.setUser = user;
      setState({
        countries: _countries?.data,
        cities: _cities?.data,
        mop: mop?.data,
        should_render: false,
      });
    } catch (error) {
      console.log(error);
    }
  };

  let arr = [];
  if (!state?.should_render) {
    if (authClass?.getUser?.token) {
      arr = [
        { path: "/", element: <AuthLogin3 /> },
        {
          path: "/app",
          element: <MainLayout />,
          children: [
            {
              path: "/",
              element: state.should_render ? null : (
                <AllSubscribers {...state} classes={classes} />
              ),
            },
            {
              path: "/dashboard",
              element: state.should_render ? null : (
                <DashboardDefault {...state} classes={classes} />
              ),
            },
            {
              path: "/all-subscribers",
              element: state.should_render ? null : (
                <AllSubscribers {...state} classes={classes} />
              ),
            },

            {
              path: "/online-request",
              element: state.should_render ? null : (
                <OnlineRequestList {...state} classes={classes} />
              ),
            },
            {
              path: "/payments",
              element: state.should_render ? null : (
                <UtilsPayments {...state} classes={classes} />
              ),
            },

            {
              path: "/invoice-bill",
              element: state.should_render ? null : (
                <UtilsInvoiceBill {...state} classes={classes} />
              ),
            },
            {
              path: "/invoices-and-payments/:id",
              element: state.should_render ? null : (
                <InvoicesAndPayments {...state} classes={classes} />
              ),
            },
            {
              path: "/invoices-and-payments",
              element: state.should_render ? null : (
                <InvoicesAndPayments {...state} classes={classes} />
              ),
            },

            {
              path: "/utils/notifications",
              element: state.should_render ? null : (
                <UtilsNotifications {...state} classes={classes} />
              ),
            },
            {
              path: "/sample-page",
              element: state.should_render ? null : (
                <SamplePage {...state} classes={classes} />
              ),
            },
            
            {
              path: "/add-subscriber",
              element: state.should_render ? null : (
                <AddNewSubscribers {...state} classes={classes} />
              ),
            },
            {
              path: "/add-subscriber/:id",
              element: state.should_render ? null : (
                <AddNewSubscribers {...state} classes={classes} />
              ),
            },
            {
              path: "/add-subscriber/:id/:type",
              element: state.should_render ? null : (
                <AddNewSubscribers {...state} classes={classes} />
              ),
            },
          ],
          // MainRoutes
        },
        AuthenticationRoutes,
      ];
    } else {
      arr = [{ path: "*", element: <AuthLogin3 reRenDerApp={reRenDerApp} /> }];
    }
  } else {
    arr = [{ path: "*", element: <SamplePage /> }];
  }
  return useRoutes(arr, config.basename);
}

export default withStyles(style)(ThemeRoutes);
