import React, { useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Divider, Grid, Stack, Typography, useMediaQuery } from "@mui/material";

// project imports
import AuthCardWrapper from "../AuthCardWrapper";
import AuthLogin from "../auth-forms/AuthLogin";
import Logo from "ui-component/Logo";

import AuthFooter from "ui-component/cards/AuthFooter";
import { authClass } from "utils/auth.utils";
import { all_fonts } from "utils/constants";

// assets

// ================================|| AUTH3 - LOGIN ||================================ //
//yhn kaam hoga
const Login = (props) => {
  const theme = useTheme();
  const params = useParams();
  const navigate = useNavigate();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));

  // eslint-disable-next-line
  const [Loader, setLoader] = useState(false);

  React.useEffect(() => {
    if (params["*"] !== "/") {
      navigate("/");
    }
    if (authClass.getUser) {
      navigate("/app");
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return Loader ? null : (
     <div className="login-bg">
      <Grid
        container
        direction="column"
        justifyContent="flex-end"
        sx={{ minHeight: "100vh" }}
      >
        <Grid item xs={12}>
          <Grid

            container
            justifyContent="center"
            alignItems="center"
            sx={{ minHeight: "calc(100vh - 68px)" }}
          >
            <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
              <AuthCardWrapper 
        style={{borderRadius:'4px'}}
        >
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item sx={{ mb: 3 }}>
                    <Link to="#">
                      <Logo
                        style={{
                          fill: "#3344B5",
                          width: "200px",
                          height: "100px",
                          marginTop: "15px",
                        }}
                      />
                    </Link>
                  </Grid>
                  <Grid item xs={12} style={{marginTop:'-20px'}}>
                    <Grid
                      container
                      direction={matchDownSM ? "column-reverse" : "row"}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Grid item>
                        <Stack
                          alignItems="center"
                          justifyContent="center"
                          spacing={1}
                        >
                          <Typography
                          style={{fontSize:'38px',fontFamily:all_fonts['Roboto'].font_g}}
                            color={"#3344B5"}
                            gutterBottom
                          >
                            HiTech Logistics
                          </Typography>
                          <Typography
                            variant="caption"
                            fontSize="16px"
                            textAlign={matchDownSM ? "center" : "inherit"}
                            style={{marginTop:'-5px'}}
                          >
                            Enter your credentials to continue
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <AuthLogin reRenDerApp={props.reRenDerApp} />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                  </Grid>
                </Grid>
              </AuthCardWrapper>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ m: 3, mt: 1 }}
          style={{ display: "flex", justifyContent: "space-evenly" }}
        >
          <AuthFooter />
        </Grid>
      </Grid>
      </div>
    // </AuthWrapper1>
  );
};

export default Login;
