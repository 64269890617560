// assets
import {
  IconDashboard,
  IconTypography,
  IconPalette,
  IconShadow,
  IconWindmill,
  IconBrandChrome,
  IconHelp,
} from "@tabler/icons";
import BookOnlineIcon from "@mui/icons-material/BookOnline";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import DashboardIcon from '@mui/icons-material/Dashboard';
import PaymentsIcon from '@mui/icons-material/Payments';
import ReceiptIcon from '@mui/icons-material/Receipt';
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';

// constant
const icons = {
  IconDashboard,
  PersonAddAltIcon,
  IconTypography,
  IconPalette,
  IconShadow,
  IconWindmill,
  BookOnlineIcon,
  IconBrandChrome,
  IconHelp,
  DashboardIcon,
  PaymentsIcon,
  ReceiptIcon,
  NotificationAddIcon
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
  id: "dashboard",
  title: "",
  type: "group",
  children: [
    {
      id: "default",
      title: "Dashboard",
      type: "item",
      url: "app/dashboard",
      icon: icons.DashboardIcon,
      // breadcrumbs: true,
    },
    {
      id: "util-typography",
      title: "Online Requests",
      type: "item",
      url: "app/online-request",
      icon: icons.NotificationAddIcon,
      // breadcrumbs: false,
    },
    {
      id: "New_User",
      title: "Subscribers",
      type: "item",
      url: "app/all-subscribers",
      icon: icons.PersonAddAltIcon,
      // breadcrumbs: true,
    },
    {
      id: "payments",
      title: "Payments",
      type: "item",
      url: "app/payments",
      icon: icons.PaymentsIcon,
      // breadcrumbs: false,
    },
    {
      id: "invoice-bill",
      title: "Invoice Bill",
      type: "item",
      url: "app/invoice-bill",
      icon: icons.ReceiptIcon,
      // breadcrumbs: false,
    },
    {
      id: "util-notication",
      title: "Notification",
      type: "item",
      icon: icons.IconShadow,
      breadcrumbs: false,
      disabled: true,
    },
    {
      id: "sample-page",
      title: "Settings",
      type: "item",
      url: "app/sample-page",
      icon: icons.IconBrandChrome,
      breadcrumbs: false,
      disabled: true,
    },
  ],
};

export default dashboard;
