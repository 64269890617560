// material-ui
import {Typography, Stack} from '@mui/material';

// ==============================|| FOOTER - AUTHENTICATION 2 & 3 ||============================== //

const AuthFooter = () => (
    <Stack direction="row" justifyContent="space-between">
        <Typography variant="subtitle1" style={{color:'#fff',fontSize:'16px'}} >
            Copyright © {new Date().getFullYear()} {" "}<a href="https://hitech-prime.com/" style={{color: '#c4c3be'}} target="_blank" underline="hover"> HiTech Prime (Private) Limited ®</a>. All Rights Reserved.        </Typography>
    </Stack>
);

export default AuthFooter;
