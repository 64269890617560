import PropTypes from 'prop-types';

// material-ui

// third-party
import PopupState, {  } from 'material-ui-popup-state';

// project imports

// assets

// styles



// ==============================|| SEARCH INPUT - MOBILE||============================== //

const MobileSearch = () => {

    return
    //  
};

MobileSearch.propTypes = {
    value: PropTypes.string,
    setValue: PropTypes.func,
    popupState: PopupState
};

// ==============================|| SEARCH INPUT ||============================== //

const SearchSection = () => {

    return (
        <>
            
        </>
    );
};

export default SearchSection;
