import { all_fonts, defaultLabelColors } from "utils/constants";

const style = (theme) => ({
  text_feild_hover: {
    "& fieldset": {
      borderRadius: 5,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: 5,
      },
      "&:hover fieldset": {
        // borderColor: "red",
      },
      "&.Mui-focused fieldset": {
        border: "1px solid #032938",
      },
    },
  },
   
  text_feild: {
    fontFamily: "Roboto-Medium",
    fontSize: 16,
  },

  mui_accordion_root: {
    width: "100%",
    background: "#E6E6FA",
    backgroundColor: "#E6E6FA",
    minHeight: `48px !important`,
    height: `48px !important`,
    paddingLeft: 15,
    borderRadius: 5,
    paddingRight: 15,
   },
  accordion_panel_border: {
    border: `1px solid #3344b5`,
    borderRadius: 5,
    boxShadow: `rgb(0 0 0 / 0%) 0px 5px 5px -3px, rgb(0 0 0 / 0%) 0px 8px 10px 1px,rgb(0 0 0 / 2%) 0px 3px 14px 2px !important`,
  },
  select_feild_hover: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: 5,
      },
      "&:hover fieldset": {
        // borderColor: "red",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#032938",
      },
    },
  },
  head_1: {
    fontSize: "",
  },
  modal_background: {
    backgroundColor: defaultLabelColors.color_b,
    background: defaultLabelColors.color_b,
    padding: "10px 20px",
  },
  display_flex_baseline: {
    display: "flex",
    alignItems: "baseline",
  },
  modal_title: {
    flex: 1,
    fontSize: 22,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: theme.palette.primary.color_d,
    letterSpacing: "0.015em",
  },
  button_width: {
    maxWidth: 150,
    maxHeight: 45,
    minWidth: 130,
    minHeight: 45,
    textTransform: "capitalize",
    boxShadow: "none",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "none",
      minWidth: "0px",
      marginBottom: 5,
    },
  },
  contained_button: {
    fontSize: 16,
    fontWeight: "none",
    lineHeight: "normal",
    letterSpacing: "0.04em",
    color: defaultLabelColors.color_h,
    background: defaultLabelColors.color_b,
    border: `1px solid ${defaultLabelColors.color_b}`,

    "&:hover": {
      border: `1px solid ${defaultLabelColors.color_b}`,
      color: defaultLabelColors.color_h,
      background: defaultLabelColors.color_b,
    },
  },
  disabled_button: {
    fontSize: 16,
    fontWeight: "none",
    lineHeight: "normal",
    letterSpacing: "0.04em",
    color: `${theme.palette.primary.color_f} !important`,
    border: `1px solid ${theme.palette.primary.color_f}`,
    background: theme.palette.primary.color_g,
  },
  outlined_button: {
    fontSize: 16,
    fontWeight: "none",
    lineHeight: "normal",
    letterSpacing: "0.04em",
    color: defaultLabelColors.color_b,
    border: `1px solid ${defaultLabelColors.color_b}`,
    background: theme.palette.primary.color_h,
    "&:hover": {
      color: defaultLabelColors.color_b,
      background: theme.palette.primary.color_h,
    },
  },
  search_input: {
    fontSize: 16,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: defaultLabelColors.color_b,
  },
  text_1_with_background: {
    fontSize: 16,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    background: defaultLabelColors.color_c,
    padding: 10,
    paddingLeft: 20,
    color: defaultLabelColors.color_a,
    textTransform: "capitalize",
  },
  text_1 : {
    fontSize: 16,
    fontFamily:all_fonts.Roboto.font_c,
    color:'#181818'
  },
  calender: {
    overrides: {
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: "red",
        },
      },
      MuiPickersDay: {
        day: {
          color: "black",
        },
        daySelected: {
          backgroundColor: "red",
        },
        dayDisabled: {
          color: "#ccc",
        },
        current: {
          color: "black",
        },
      },
    },
  },
  rootCounterFull: {
    flexGrow: 1,
  },
  counterIcon: {
    color: defaultLabelColors.color_h,
    opacity: 0.7,
    fontSize: 84
  },
  root: {
    flexGrow: 1,
    justifyContent: "space-between",
    alignItems: "flex-start",
    border: `2px solid ${defaultLabelColors.color_c}`,
    background: theme.palette.primary.color_h,
    boxShadow: `4px 4px 5px -4px rgb(102 102 102)`,
    padding: 10,
    height: 190,
    marginBottom: 6,

    display: "flex",
    [theme.breakpoints.up("sm")]: {
      height: 126,
      marginBottom: -1,
      alignItems: "flex-end",
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
    "& > *": {
      padding: "0 5px",
    },
  },
  title: {
    color: defaultLabelColors.color_b,
    fontSize: 14,
    [theme.breakpoints.up("sm")]: {
      fontSize: 18,
    },
  },
  counter: {
    color: `${defaultLabelColors.color_b} !important`,
    fontSize: 30,
  },
  customContent: {
    textAlign: "right",
  }, 
});
export default style;
