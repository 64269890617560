import { features } from "utils/constants";
import { get, patch, post } from "./HttpProvider";

const SERVICE_URLS = {
  users: "/users",
  subs: "/subscribers",
  status: "/subscribers/active-inactive",
  update: "/subscribers/update-subscriber",
  payment: "/payment/subscriber-payments",
  invoice: "/payment/subscriber-invoices",
  invoiceByid: "/payment/subscriber-invoices/get-by-id",
  packages: "/parent-subscriptions",
  resendEmail: "/subscribers/resend-email",
  logout: "/subscribers/logout-subscriber",
};
const updateToken = (url, data) =>
  patch(`${SERVICE_URLS.users}/${url}`, data, {});
const resendEmail = (url) => get(`${SERVICE_URLS.resendEmail}${url}`, {}, {});
const signIn = (url, data) =>
  post(`${SERVICE_URLS.users}${url}`, data, { feature: features.login });
const getSubs = (url) =>
  get(`${SERVICE_URLS.subs}${url}`, {}, { features: features.login });
const getSubsByID = (url) =>
  get(`${SERVICE_URLS.subs}${url}`, {}, { features: features.login });
const getPaymntInfo = (url) =>
  get(`${SERVICE_URLS.payment}${url}`, {}, { features: features.login });
const getAllPaymntInfo = () =>
  get(`${SERVICE_URLS.payment}`, {}, { features: features.login });
const getInoivces = (url) =>
  get(`${SERVICE_URLS.invoice}${url}`, {}, { features: features.login });
const getPackageValue = (url) =>
  get(`${SERVICE_URLS.packages}${url}`, {}, { features: features.login });
const getOnlineRequests = (url) =>
  get(`${SERVICE_URLS.subs}${url}`, {}, { features: features.login });
const getFromAPI = (url) => get(`${url}`, {}, { features: features.getParent });
const updateStatus = (url, data) =>
  patch(`${SERVICE_URLS.status}${url}`, data, { feature: features.update });
const logoutSubscriber = (url, data) =>
  get(`${SERVICE_URLS.logout}${url}`, data, { feature: features.update });
const updateSubscriber = (url, data) =>
  patch(`${SERVICE_URLS.update}${url}`, data, { feature: features.update });
const addSubs = (url, data) =>
  post(`${SERVICE_URLS.subs}${url}`, data, { features: features.addSub });
const addPayments = (url, data) =>
  post(`${SERVICE_URLS.payment}${url}`, data, { features: features.payment });
const getInvoiceByID = (url) =>
  get(`${SERVICE_URLS.invoiceByid}${url}`, {}, { features: features.login });

const uploadFile = (url, data) =>
  post(`${url}`, data, { features: features.payment });

export const apiServices = {
  signIn,
  getSubs,
  addSubs,
  getOnlineRequests,
  getInoivces,
  updateStatus,
  getFromAPI,
  getSubsByID,
  updateSubscriber,
  getPaymntInfo,
  addPayments,
  getInvoiceByID,
  uploadFile,
  getPackageValue,
  updateToken,
  resendEmail,
  getAllPaymntInfo,
  logoutSubscriber,
};
export default apiServices;
