import { useState } from "react";
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Snackbar,
  SnackbarContent,
  TextField,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import apiServices from "services/RequestHandler";
import { authClass } from "utils/auth.utils";
import localStoreUtil from "utils/localstore.util";
import "./style.css";

// ============================|| FIREBASE - LOGIN ||============================ //

const FirebaseLogin = (props) => {
  let navigate = useNavigate();
 
    const [login, setLogin] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleLogin = async () => {
    let finalObj = {
      email: login.email,
      password: login.password,
    };
    const response = await apiServices.signIn("/signin", finalObj);
   

    if (!response?.data?.id) {
      setError(
        response?.data?.error ||
          response?.data?.isNotEmpty ||
          response?.data?.isEmail
      );
      return;
    }
    setOpen(true);
    authClass.setUser = response.data;
    localStoreUtil.store_data("user", response.data);
    localStoreUtil.store_data("token", response.data?.token);
    setTimeout(() => {
    props.reRenDerApp(true);
    navigate("/app");
  }, 1000);
  };
  const handleChangeInput = (event) => {
    event.preventDefault();
    setLogin({
      ...login,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <>
      <Grid container direction="column" justifyContent="center" spacing={2}>
        <Grid item xs={12}></Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid xs={12} item>
          <label style={{ paddingLeft: "10px" }}>Email</label>
          <TextField
            style={{ fontSize: "16px" }}
            className="TextField-without-border-radius"
            fullWidth={true}
            value={login.email}
            name={"email"}
            onChange={(event) => handleChangeInput(event)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid xs={12} item>
          <label style={{ paddingLeft: "10px" }}>Password</label>
          <TextField
            style={{ fontSize: "16px" }}
            className="TextField-without-border-radius"
            fullWidth={true}
            name={"password"}
            type={showPassword ? "text" : "password"}
            value={login.password}
            onChange={(event) => handleChangeInput(event)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                    size="large"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        <Grid xs={12}>
          <InputLabel
            fullWidth={true}
            style={{
              color: "red",
              marginTop: "12px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {" "}
            {error}{" "}
          </InputLabel>
        </Grid>
      </Grid>
      <Grid
        xs={12}
        style={{
          marginTop: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          onClick={() => handleLogin()}
          variant="contained"
          style={{
            maxwidth: 180,
            maxHeight: 145,
            minWidth: 150,
            minHeight: 45,
            fontSize: 16,
            fontWeight: "none",
            lineHeight: "normal",
            letterSpacing: "0.04em",
            color: "#f8f8f8",
            background: "#3344b5",
            border: "1px solid #3344b5",
            textTransform: "capitalize",
          }}
          endIcon={
            <ArrowForwardIcon
              style={{ fontSize: `20px !important`, color: "#f8f8f8" }}
            />
          }
        >
          Continue
        </Button>
      </Grid>
      <Snackbar
        style={{ maxWidth: "450px" }}
        autoHideDuration={3000}
        open={open}
        onClose={handleClose}
      >
        <SnackbarContent
          style={{
            backgroundColor: "#5CB85C",
            maxWidth: 450,
            alignItems: "center",
            fontSize: 20,
            fontFamily: "Roboto-Regular",
          }}
          message={"You've signed-in successfully."}
        />
      </Snackbar>
    </>
  );
};

export default FirebaseLogin;
